import { getTypeByDomain } from '@MGPD/myasurion-shared';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { createClient } from 'contentful';
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { ReactNode } from 'react';

import { iArticlesJSON } from '../components/ExpertTipsList';
import { ArticleCardProps, ContentfulImage, ImageEntry } from '../types/types';

/* eslint-disable no-console */
export const printVersion = () => {
    console.log(`%c ${APP_NAME} v${APP_VERSION}  `, 'background: #9747DD; color: #FFF');
};

export const stringToSlug = (str: string): string => {
    return str
        .toLowerCase() // Convert the string to lowercase
        .replace(/\s+/g, '-') // Replace spaces with dashes
        .replace(/[^a-z0-9-]/g, '') // Remove non-alphanumeric characters and dashes
        .replace(/-{2,}/g, '-') // Replace consecutive dashes with a single dash
        .replace(/^-+|-+$/g, ''); // Remove dashes from the beginning or end of the slug
};

export const PlanSS3 = 'ss3';
export const PlanSS4 = 'ss4';
export const subscriptionSkuPlanType = (sku: string): typeof PlanSS3 | typeof PlanSS4 => {
    const ss4SKUs = [
        'SmartSupport $14',
        'SmartSupport $10',
        'Device Refresh Standalone RRP $4',
        'Device Refresh Hard-bundled $2 supply fee',
    ];
    return ss4SKUs.includes(sku) ? PlanSS4 : PlanSS3;
};

export const countAvailableProductFeatures = (services: any[], features: string[]) => {
    let count = 0;
    services.map((service) => {
        if (service && service.title && isProductFeatureIncluded(service.title, features)) {
            count++;
        }
    });
    return count;
};

export const showTitle = (isComplete: boolean, count: number) => {
    return isComplete && count > 0 ? true : false;
};

export const isProductFeatureIncluded = (featureName: string, featureSets: string[]) => {
    return featureSets.indexOf(featureName.toLowerCase()) > -1 ? true : false;
};

export const isProcessingServiceRequest = (
    incidentPaths: { type: string; [key: string]: string | number }[]
) => {
    return incidentPaths.findIndex(
        (i) => i.type.toLowerCase() === 'ResumeServiceRequest'.toLowerCase()
    ) > -1
        ? true
        : false;
};

export const formatMdnString = (mdnString: string) => {
    const match = mdnString.match(/^(\(\+?\d+\))(.*)$/);
    let returnString = mdnString,
        callingCode = '';
    if (match) {
        callingCode = match[1].replace('+', '') + ' ';
        returnString = match[2];
    }
    return (
        callingCode +
        returnString
            .replace(/\D/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim()
    );
};

type iMapSRImageId = {
    swap?: string;
    replace?: string;
    refresh?: string;
    'device-repair'?: string;
    'screen-repair'?: string;
    'battery-replacement'?: string;
    'screen-repair-&-battery-replacement'?: string;
};
export const mapSRImage = (imageId: keyof iMapSRImageId, map: iMapSRImageId) => {
    return map[imageId];
};

export class ArticleCardTransformer implements ArticleCardProps {
    image?: string | undefined;
    imageMobile: string;
    imageDesktop: string;
    size: 'lg' | 'md';
    tag: string;
    category?: string | undefined;
    title: string;
    description: string;
    linkText: string;
    linkType: 'button' | 'link';
    link: string;

    constructor(articleJSON: iArticlesJSON) {
        this.title = articleJSON?.title;
        this.description = articleJSON?.description;
        // this.image = articleJSON.thumbnails?.default || articleJSON.thumbnail?.default;
        // this.imageDesktop = articleJSON.thumbnails?.default || articleJSON.thumbnail?.default || '';
        // this.imageMobile = articleJSON.thumbnails?.small || articleJSON.thumbnail?.default || '';
        this.image = articleJSON?.thumbnail;
        this.imageDesktop = articleJSON?.thumbnail || '';
        this.imageMobile = articleJSON?.thumbnail || '';
        this.link = `/${articleJSON.id}`;
        this.size = 'md';
        this.tag = articleJSON?.tags?.length > 0 ? articleJSON?.tags[0] : '';
        this.linkText = 'Learn More';
        this.linkType = 'link';
        this.category = articleJSON?.categories?.length > 0 ? articleJSON?.categories[0] : '';
    }
}

async function getFirebaseConfig() {
    const firebaseConfig = {
        apiKey: import.meta.env.VITE_REMOTE_CONFIG_API_KEY,
        authDomain: import.meta.env.VITE_REMOTE_CONFIG_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_REMOTE_CONFIG_PROJECT_ID,
        storageBucket: import.meta.env.VITE_REMOTE_CONFIG_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_REMOTE_CONFIG_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_REMOTE_CONFIG_APP_ID,
        measurementId: import.meta.env.VITE_REMOTE_CONFIG_MEASUREMENT_ID,
    };

    const app = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    await fetchAndActivate(remoteConfig);

    return remoteConfig;
}

export async function getContentfulImage(slug: string): Promise<string | null> {
    try {
        const remoteConfig = await getFirebaseConfig();
        const spaceId = getValue(
            remoteConfig,
            import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_SPACE_ID_REF
        ).asString();

        const accessToken = getValue(
            remoteConfig,
            import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ACCESS_TOKEN_REF
        ).asString();

        const environment = getValue(
            remoteConfig,
            import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ENVIRONMENT_REF
        ).asString();

        const client = createClient({
            space: spaceId,
            accessToken: accessToken,
            environment: environment,
        });

        const response = await client.getEntries<ImageEntry>({
            content_type: 'genericIdImageDescriptionSlug',
            'fields.slug': slug,
        });

        const entry = response.items[0]?.fields.image;
        const entryImage: ContentfulImage = entry;

        return entryImage.fields?.file.url || null;
    } catch (error) {
        console.error('Error fetching entry:', error);
        throw error;
    }
}

export async function getContentfulEntry(
    searchParams: Record<string, any>,
    contentType: string
): Promise<Record<string, any> | null> {
    try {
        const remoteConfig = await getFirebaseConfig();
        const spaceId = getValue(
            remoteConfig,
            import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_SPACE_ID_REF
        ).asString();

        const accessToken = getValue(
            remoteConfig,
            import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ACCESS_TOKEN_REF
        ).asString();

        const environment = getValue(
            remoteConfig,
            import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ENVIRONMENT_REF
        ).asString();

        const client = createClient({
            space: spaceId,
            accessToken: accessToken,
            environment: environment,
        });

        const response = await client.getEntries<any>({
            content_type: contentType,
            ...searchParams,
        });

        const entry = response.items[0].fields;

        return entry || null;
    } catch (error) {
        console.error('Error fetching entry:', error);
        throw error;
    }
}

export const processContentfulImageToProgressiveJpg = (url: string) => {
    return `${url}?fm=jpg&fl=progressive`;
};

export const processContentfulImageToWebp = (url: string, quality: string | null = null) => {
    return `${url}?fm=webp${quality ? '&q=' + quality : ''}`;
};

export const richTextOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => <span id={node}>{children}</span>,
        [BLOCKS.HEADING_1]: (node: any, children: ReactNode) => <h1 id={node}>{children}</h1>,
        [BLOCKS.HEADING_2]: (node: any, children: ReactNode) => <h2 id={node}>{children}</h2>,
        [BLOCKS.HEADING_3]: (node: any, children: ReactNode) => <h3 id={node}>{children}</h3>,
        [BLOCKS.HEADING_4]: (node: any, children: ReactNode) => <h4 id={node}>{children}</h4>,
        [BLOCKS.HEADING_5]: (node: any, children: ReactNode) => <h5 id={node}>{children}</h5>,
        [BLOCKS.HEADING_6]: (node: any, children: ReactNode) => <h6 id={node}>{children}</h6>,
        [BLOCKS.UL_LIST]: (node: any, children: ReactNode) => <ul id={node}>{children}</ul>,
        [BLOCKS.OL_LIST]: (node: any, children: ReactNode) => <ol id={node}>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node: any, children: ReactNode) => <li id={node}>{children}</li>,
        [BLOCKS.QUOTE]: (node: any, children: ReactNode) => (
            <blockquote id={node}>{children}</blockquote>
        ),
        [BLOCKS.HR]: () => <hr />,
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { description, file } = node.data.target.fields;
            const url = file.url;
            const type = file.contentType;
            const mimeType = type ? type.split('/')[0] : 'image';

            if (mimeType === 'image') {
                const { width, height } = file.details.image;
                return (
                    <figure>
                        <img src={url} alt={description} width={width} height={height} />
                    </figure>
                );
            }

            if (mimeType === 'video') {
                return (
                    <video src={url} controls>
                        {description && (
                            <track
                                kind="subtitles"
                                label="English"
                                srcLang="en"
                                src={description}
                            />
                        )}
                    </video>
                );
            }

            return null;
        },
        [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        ),
    },
    renderText: (text: string) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

export const millisToDate = (ms: string, locale: string) => {
    const date = new Date(+ms);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    } as const;

    return date.toLocaleDateString(locale, options);
};

export const appendPortalToStringIfRepair = (str: string) => {
    const isLocalDevelopment: boolean = import.meta.env.VITE_IS_LOCAL_DEVELOPMENT === 'true';

    if (!isLocalDevelopment) {
        const portalType = getTypeByDomain();

        if (portalType === 'repair') {
            return '/portal' + str;
        }
    }
    return str;
};

export const getCarrierFileUrl = (
    carrier: string,
    portalType: 'device_protection' | 'repair',
    fileType: 't&c' | 'privacy' | 'terms-of-use',
    language: string | undefined = 'en-US'
) => {
    const urlCarrier = carrier ?? 'asurion_techcare';
    const urlPortalType = portalType;
    const urlLanguage = language;
    const urlFileType =
        fileType === 't&c'
            ? 'Terms-and-Conditions.pdf'
            : fileType === 'privacy'
            ? 'Privacy-Policy.pdf'
            : fileType === 'terms-of-use'
            ? 'Terms-of-Use.pdf'
            : 'Terms-of-Use.pdf';
    // TODO: Apply file map here

    const formatAppendSubPath = (subpath?: string) => {
        return subpath ? `/${subpath}` : '';
    };

    return (
        '/files' +
        formatAppendSubPath(urlCarrier) +
        formatAppendSubPath(urlPortalType) +
        formatAppendSubPath(urlLanguage) +
        formatAppendSubPath(urlFileType)
    );
};
export const throttle = (callback: any, delay: number) => {
    let lastCall = 0;

    return function (...args: any) {
        const now = new Date().getTime();
        if (now - lastCall >= delay) {
            lastCall = now;
            callback(...args);
        }
    };
};
