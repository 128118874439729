import { getCarrierByDomain } from '@MGPD/myasurion-shared';
import { Global } from '@emotion/react';

import ApercuBlack from '/fonts/ApercuPro/Apercu-Black-Pro.ttf';
import ApercuBold from '/fonts/ApercuPro/Apercu-Bold-Pro.ttf';
import ApercuLight from '/fonts/ApercuPro/Apercu-Light-Pro.ttf';
import ApercuRegular from '/fonts/ApercuPro/Apercu-Regular-Pro.ttf';
import ApercuMonoLight from '/fonts/ApercuPro/ApercuMono-Light-Pro.ttf';
import ApercuMonoRegular from '/fonts/ApercuPro/ApercuMono-Regular-Pro.ttf';
import MaxisBlack from '/fonts/Maxis/Maxis-Black.otf';
import MaxisBold from '/fonts/Maxis/Maxis-Bold.otf';
import MaxisExtraBold from '/fonts/Maxis/Maxis-ExtraBold.otf';
import MaxisLight from '/fonts/Maxis/Maxis-Light.otf';
import MaxisRegular from '/fonts/Maxis/Maxis-Regular.otf';
import MaxisSemiBold from '/fonts/Maxis/Maxis-Semibold.otf';

const getRequiredFont = (carrier: string) => {
    const font = {
        light: { url: ApercuLight, local: 'Apercu-Light-Pro', family: 'ApercuPro' },
        regular: { url: ApercuRegular, local: 'Apercu-Regular-Pro', family: 'ApercuPro' },
        bold: { url: ApercuBold, local: 'Apercu-Bold-Pro', family: 'ApercuPro' },
        black: { url: ApercuBlack, local: 'Apercu-Black-Pro', family: 'ApercuPro' },
        monolight: {
            url: ApercuMonoLight,
            local: 'ApercuMono-Light-Pro',
            family: 'ApercuPro Mono',
        },
        monoregular: {
            url: ApercuMonoRegular,
            local: 'ApercuMono-Regular-Pro',
            family: 'ApercuPro Mono',
        },
        semibold: { url: ApercuBold, local: 'Apercu-Bold-Pro', family: 'ApercuPro' },
        extrabold: { url: ApercuBold, local: 'Apercu-Bold-Pro', family: 'ApercuPro' },
    };
    if (carrier === 'maxis') {
        font.light = { url: MaxisLight, local: 'Maxis-Light', family: 'Maxis' };
        font.regular = { url: MaxisRegular, local: 'Maxis-Regular', family: 'Maxis' };
        font.bold = { url: MaxisBold, local: 'Maxis-Bold', family: 'Maxis' };
        font.black = { url: MaxisBlack, local: 'Maxis-Black', family: 'Maxis' };
        font.semibold = { url: MaxisSemiBold, local: 'Maxis-Semibold', family: 'Maxis' };
        font.extrabold = { url: MaxisExtraBold, local: 'Maxis-Extrabold', family: 'Maxis' };
    }

    return font;
};

const Fonts = () => {
    const carrier = getCarrierByDomain();
    const font = getRequiredFont(carrier);

    return (
        <Global
            styles={`
      @font-face {
        font-family: "${font ? font.light.family : 'ApercuPro'}";
        src: local("${font ? font.light.local : 'Apercu-Light-Pro'}"), url(${
                font ? font.light.url : ApercuLight
            }) format("truetype");
        font-display: block;
        font-weight: 300;
      }

      @font-face {
        font-family: "${font ? font.regular.family : 'ApercuPro'}";
        src: local("${font ? font.regular.local : 'Apercu-Regular-Pro'}"), url(${
                font ? font.regular.url : ApercuRegular
            }) format("truetype");
        font-display: block;
        font-weight: 500;
      }
      
      @font-face {
        font-family: "${font ? font.bold.family : 'ApercuPro'}";
        src: local("${font ? font.bold.local : 'Apercu-Bold-Pro'}"), url(${
                font ? font.bold.url : ApercuBold
            }) format("truetype");
        font-display: block;
        font-weight: 700;
      }

      @font-face {
        font-family: "${font ? font.black.family : 'ApercuPro'}";
        src: local("${font ? font.black.local : 'Apercu-Black-Pro'}"), url(${
                font ? font.black.url : ApercuBlack
            }) format("truetype");
        font-display: block;
        font-weight: 900;
      }

      @font-face {
        font-family: "${font ? font.monolight.family : 'ApercuPro Mono'}";
        src: local("${font ? font.monolight.local : 'ApercuMono-Light-Pro'}"), url(${
                font ? font.monolight.url : ApercuMonoLight
            }) format("truetype");
        font-display: block;
        font-weight: 300;
      }

      @font-face {
        font-family: "${font ? font.monoregular.family : 'ApercuPro Mono'}";
        src: local("${font ? font.monoregular.local : 'ApercuMono-Regular-Pro'}"), url(${
                font ? font.monoregular.url : ApercuMonoRegular
            }) format("truetype");
        font-display: block;
        font-weight: 500;
      }
      `}
        />
    );
};

export default Fonts;
