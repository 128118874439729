export const DocumentFolders: { [key: string]: string }[] = [
    {
        id: 'Policy',
        label: 'Billing history',
        entryPrefix: 'Billing date',
        imgUrl: 'https://seahzn-myasurion-sqa.apac.nonprod-asurion53.com/b43e458fe871eb3af713ba0f24cad125.png',
    },
    {
        id: 'Claim',
        label: 'Service request fee',
        entryPrefix: 'Service request date',
        imgUrl: 'https://seahzn-myasurion-sqa.apac.nonprod-asurion53.com/a94fd76944ff8bc00d451e234e741235.png',
    },
];

export const INVOICE_NOTE =
    'Subscription fees are billed at the beginning of each subscription period. Tax invoice/receipt may take up to 15 days after the billing date to be available. You can retrieve up to 24 months of your past tax invoices.';
