import { Global } from '@emotion/react';

const ChakraExtension = () => (
    <Global
        styles={`
        .chakra-select__wrapper {
          height: 100% !important;
        }
      `}
    />
);

export default ChakraExtension;
