import { Text } from '@chakra-ui/layout';
import { PropsWithChildren } from 'react';

const ScreenReaderText = ({
    text = 'Read more about this',
    children,
}: { text?: string } & PropsWithChildren) => {
    return (
        <Text
            border={0}
            clipPath={'inset(50%)'}
            h={'1px'}
            m={'-1px'}
            overflow="hidden"
            p="0"
            // position="absolute"
            wordBreak="normal"
            style={{
                border: 0,
                clipPath: 'inset(50%)',
                clip: 'rect(1px, 1px, 1px, 1px)',
                height: '1px',
                margin: '-1px',
                overflow: 'hidden',
                padding: '0',
                // position: 'absolute',
                width: '1px',
                wordWrap: 'normal',
                wordBreak: 'normal',
            }}
        >
            {children || text}
        </Text>
    );
};

export default ScreenReaderText;
